.templates-container {
  flex-direction: column;
  width: 100%;

  .templates-list {
    width: 100%;
  }
}

.templates-filter-container {
  text-align: center;
  padding: 2em;

  .ant-btn {
    margin: 0.5em;
  }
}
