.ant-back-top {
  z-index: 110 !important;

  .back-to-top-container {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $dark-green;
    color: $bg-white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
}
