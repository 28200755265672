.sheets-page {
  .sheets-page-header {
    display: flex;
    justify-content: space-between;
  }

  .sheets-container {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0;
    margin-left: -1rem;
    margin-right: -1rem;
    justify-content: center;

    .sheet {
      position: relative;
      width: 33%;
      text-align: center;

      .sheet-item {
        height: 180px;
        flex-grow: 1;
        flex-shrink: 2;
        padding: 1rem;
        font-weight: 400;
        display: flex;
        align-items: center;
        background-color: rgba(65, 150, 53, 0.05);
        cursor: pointer;
        margin: 1rem;
        border-radius: 5px;
        min-width: 250px;
        transition: all ease-in-out 200ms;
        position: relative;
        font-size: 1.2rem;

        .sheet-button-container {
          position: absolute;
          bottom: 0;
          display: flex;
          width: 100%;

          .sheet-button {
            width: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;

            button {
              width: 100%;
            }
          }
        }

        .sheet-view-icon {
          position: absolute;
          right: 1rem;
          top: 0.5rem;
          color: #d6d6d6;
          transition: all ease-in-out 200ms;
        }


        .sheet-bg-icon {
          & .anticon {
            transition: all ease-in-out 200ms;
            position: absolute;
            opacity: 0.3;
            font-size: 4rem;
            color: #d6d6d6;
            right: 1rem;
            bottom: 1rem;
          }
        }

        &:hover {
          box-shadow: 0 0 5px 0 #d6d6d6;
          border-left: 5px solid #2E6434;

          .sheet-bg-icon {
            & .anticon {
              opacity: 0.8;

            }
          }
        }
      }

      .sheet-delete-icon {
        transition: all ease-in-out 200ms;
        position: absolute;

        & > button {
          left: 1.5rem;
          bottom: 3.5rem;
        }

        .anticon {
          color: #d6d6d6;
        }
      }

      .sheet-edit-icon {
        transition: all ease-in-out 200ms;
        position: absolute;

        & > button {
          left: 3.5rem;
          bottom: 3.5rem;
        }

        .anticon {
          color: #d6d6d6;
        }
      }

      &:hover {
        .sheet-delete-icon {
          & .anticon {
            color: red;
            opacity: 0.4;

            &:hover {
              opacity: 0.6;
            }
          }
        }

        .sheet-edit-icon {
          & .anticon {
            color: #e8bd20;
            opacity: 0.8;

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }

    .empty-sheets-container {
      background-color: rgba(65, 150, 53, 0.05);;
      width: 100%;
      padding: 4rem;

      button {
        background: #2E6434;
        border-color: #2E6434;
        height: 100%;
        color: #fefefe;
        font-weight: 400;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .sheets-container {
    .row {
      flex-direction: column !important;
    }

    .sheet {
      width: 100%;
    }
  }
}
