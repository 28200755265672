.section-container {
  padding-top: 65px;

  .section {
    padding: 4rem 10rem;
    transition: all 0.3s;
    height: 100%;

    &.bg-green {
      background: $bg-green;
    }

    .section-content {
      display: flex;

      .section-left {
        //display: flex;
        width: 100%;
        background: $bg-white;
        transition: top 5s, left 5s;
        height: 100%;
      }

      .section-right {
        width: 100%;
        //display: flex;
        background: $bg-white;
        transition: top 5s, left 5s;
        height: 100%;
      }
    }

    .section-header {
      color: $text-color;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      font-size: 2.4rem;
      font-weight: 600;
      padding-bottom: 1em;
      text-align: center;

      .section-header-description {
        font-weight: 500;
        font-size: 1.2rem;
      }
    }


  }
}

@media screen and (max-width: 1024px) {
  .section-container {
    .section {
      padding: 2rem 1rem;
      flex-direction: column;

      .section-content {
        padding: 2rem 1rem;
        flex-direction: column;
      }
    }
  }
}
