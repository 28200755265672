@import "constants";
@import "../../components/_components";
@import "../../views/views";
@import "section";
@import '../../../node_modules/antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: $general-font-family !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  display: block;
  height: 100vh;
}

.ant-layout {
  display: block;
}

.ant-layout-footer {
  margin-top: 5rem;
}

.App {
  text-align: center;
  background: #f6f9fa;
  color: #575e65;
}

.App .container {
  padding-top: 5rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App button {
  font-weight: 600;
}

.App button i {
  padding-left: 0.5em;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error {
  background-color: red;
}

/* colors : https://www.canva.com/learn/website-color-schemes/ 9 numaralı
  #D7CEC7 -> F7F6E7
  #565656
  #76323F
  #C09F80 */
