@import "user-info/user-info";

.header {
  background-color: $bg-white;
  position: fixed;
  position: -ms-device-fixed;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 999;
  color: $text-color;
  height: 65px;
  box-shadow: 0 2px 8px #f0f1f2;

  a:hover {
    color: $dark-green;
  }

  .phone-nav-bar-wrapper {
    .phone-nav-bar, .phone-nav-bar:after, .phone-nav-bar:before {
      transition: all ease-in-out 200ms;
      background: $text-color;
    }
  }

  .phone-nav-bar-wrapper:hover {
    .phone-nav-bar, .phone-nav-bar:before, .phone-nav-bar:after {
      background: $dark-green;
    }
  }

  .header-container {
    display: flex;
    transition: all ease-in-out 300ms;
    justify-content: space-between;
    width: 100%;
    padding: 0rem 10rem;
    align-items: center;

    .header-logo {
      font-size: 24px;
      font-weight: 600;
    }

    .menu {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .login-button-container {
      color: $text-color;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      button {
        background-color: $dark-green;
        justify-content: space-around;
        color: #fefefe;
        display: flex;
        font-weight: 600;
        align-items: center;
        width: 7rem;
        border-color: $dark-green;

        .anticon {
          margin-left: 0;
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .mobile-header {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-right: 1rem;

    & .anticon {
      cursor: pointer;
      font-size: 1.4rem;

      &:hover {
        color: $dark-green;
      }
    }
  }
}

.mobile-drawer {
  .ant-drawer-header {
    background: $light-green;

    .user-image {
      display: flex;
      justify-content: center;

      img {
        border-radius: 50%;
        width: 5rem;
        border: 2px solid #fefefeab;
      }
    }

    .user-info {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      color: #fefefe;
    }
  }
}

.menu-container {
  display: flex;

  &.horizontal {
    a {
      padding-right: 2.5rem;
      font-weight: 600;
    }
  }

  &.inline {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;
    align-items: center;

    .menu {
      height: 40px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      border-bottom: 0.1px solid #2e64341f;

      &:hover {
        color: $dark-green;
      }
    }

    .login-button-container {
      width: 100%;

      button {
        background-color: #ffffff;
        width: 100%;
        justify-content: space-around;
        color: #0B0914;
        display: flex;
        font-weight: 600;
        align-items: center;
        border: none;
        box-shadow: none;
        height: 40px;

        .anticon {
          display: none;
          margin-left: 0;
        }

        span {
          margin-left: 0;
        }

        &:hover {
          color: $dark-green;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .header .header-container {
    padding: 0 1rem;
  }
}

@media only screen and (max-width: 501px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
