.user-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  border-radius: 50px;
  padding-left: 1rem;
  background-color: $dark-green;
  padding-top: 3px;
  padding-bottom: 3px;


  .ant-dropdown-trigger {
    color: #fefefe;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 1rem !important;
    font-weight: 400 !important;
    font-size: 0.9rem;
    transition: all ease-in-out 200ms;

    &:hover {
      color: #fefefe;
      opacity: 0.8;
    }
  }

  img {
    height: 2.4rem;
    border-radius: 50%;
    border: 2px solid;
    margin-right: 0.8em;
    margin-left: 0.5rem;

  }
}

.user-info-dropdown-overlay {
  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    font-size: 1rem;
    padding: 0.8rem;
  }
}
