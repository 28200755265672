.blog-container {
  .blog-item-container {
    padding-bottom: 1rem;
    border-bottom: 1px solid #d6d6d6;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all ease-in-out 200ms;

    &:hover {
      .blog-header {
        border-left: 0.5rem solid;
        padding-left: 0.5rem;
        color: $light-green;
        transition: all ease-in-out 200ms;
      }

      .blog-item-user {
        color: $light-green;
      }

      border-bottom: 2px solid $light-green;
    }

    .blog-header {
      font-size: 1.4rem;
      font-weight: 600;
    }

    .blog-information {
      font-size: 1.1rem;
      padding: 0.2rem 0;

      .blog-item-date {
        padding-right: 0.5rem;
        font-weight: 600;
      }

      .blog-item-user {
        padding-left: 0.5rem;
        opacity: 0.7;

      }
    }
  }
}
