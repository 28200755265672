.pricing-container {
  display: flex;
  justify-content: center;
  width: 100%;

  .pricing-item {
    padding: 2rem;
    width: 100%;
    background: $bg-white;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1rem;
    border-radius: 20px;

    .pricing-header {
      font-weight: 800;
      font-size: 1.4rem;
      padding-bottom: 1rem;
    }

    .pricing-content {
      .content-header-desc {
        font-weight: 200;
        font-size: 1.2rem;
        padding-bottom: 1em;
      }

      ul {
        border-bottom: 1px solid #d6d6d6;
        border-bottom-style: dashed;
        padding: 1em 0;

        .content-description-item {
          display: flex;
          font-size: 1rem;
          padding-bottom: 0.5rem;
          font-weight: 500;

          .anticon {
            padding-right: 0.5em;
            padding-top: 0.2em;
            color: $dark-green;
          }
        }
      }

      .price-content {
        padding-bottom: 1rem;
        font-size: 1.6rem;
        font-weight: 800;

        span {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }

    .pricing-footer {
      display: flex;
      height: 100%;
      justify-content: flex-end;
      flex-direction: column;

      button {
        background: #2E6434;
        border-color: #2E6434;
        font-weight: 600;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .pricing-container {
    flex-direction: column;
  }
}
