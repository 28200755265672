.created-sheet-container {
  text-align: center;
  background: #ffffff;
  font-size: 1.6rem;
  padding: 2em 0;

  & a {
    color: $dark-green;
    font-weight: 600;
  }

  & .created-sheet-icon {
    & .anticon {
      color: $dark-green;

      & svg {
        font-size: 2.5rem;
      }
    }
  }

  .redirect-sheets-container {
    padding: 2em;

    .redirect-sheets-label {
      color: #000000D8 !important;
    }

    .redirect-sheets-redirect {
      color: #2E6434;
      font-weight: 600;
      cursor: pointer !important;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .share-sheet-button-container {
    padding-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .share-sheet-icon {
      cursor: pointer;
      padding-left: 0.5em;
      padding-right: 0.5em;

      &.facebook {
        & svg {
          color: #4267B2;
        }
      }

      &.twitter {
        & svg {
          color: #1DA1F2;
        }
      }

      &.whatsapp {
        & svg {
          color: #25D366;
        }
      }

      & svg {
        font-size: 2.5rem;
      }
    }
  }
}

.created-sheet-container span {
  color: red;
}

.created-sheet-container-header {

}

.ant-steps-item-container {
  display: flex;
  align-items: center;
}

.ant-steps-icon {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.ant-steps-item-icon {
  width: 3rem;
  height: 3rem;
}

.steps-action {
  display: flex;
  justify-content: center;
}
