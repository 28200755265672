.conf-desc-parent {
  padding: 1rem;
  font-size: 1.2rem;
  font-weight: 600;

  .conf-desc-sub {
    padding-top: 1rem;
    padding-left: 2rem;

    ul {
      list-style-type: disc;

      li {
        padding-top: 1rem;

        &:nth-child(odd) {
          color: $dark-green;
        }
      }
    }
  }
}
